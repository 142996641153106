import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/ru";

import { Stage, StageType, StageTypes } from "../../../../types/Stage.interface";
import { RootState } from "../../../../types/State.interface";
import { calculateRemainedTime, declination, itemIsAnnouncement, itemIsCurrent, itemIsFinished, sortArrByValue } from "../../../../utils";
import { useOuterClick } from "../../../../utils/hooks/useOnOutsideClick";
import GenerationModal from "../../../pages/Generation/GenerationModal/GenerationModal";
import StageSelectorModal from "../../../containers/Modals/StageSelectorModal/StageSelectorModal";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { showWarningToast } from "../../../../store/actions/ToastActions";
import { getCardName, getStageName, getFrontDescriptions } from "./StageTapeCard.constants";
import "./StagesTapeCard.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { selectIsUserReady } from "../../../../store/reducers/profile";

interface StagesTapeCardProps {
  type: StageType;
  stages: Stage[];
  stagesLoaded: boolean;
}

const StagesTapeCard = ({ type, stages, stagesLoaded }: StagesTapeCardProps) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const isUserReady = useSelector(selectIsUserReady);

  const [generationModalOpen, setGenerationModalOpen] = useState(false);
  const [generationStage, setGenerationStage] = useState<Stage>(null);

  const cardInnerRef = useOuterClick<HTMLInputElement>(() => {
    flipCard("unset");
    canFlipped = true;
  });
  let canFlipped = true;
  const [cardName, setCardName] = useState(getCardName(type));
  const [stageName, setStageName] = useState(getStageName(type));
  const [currentStageFrontDescriptions, setFrontDescriptions] = useState(getFrontDescriptions(type));
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!stages.length || !isUserReady) {
      return;
    }

    const actions = appContext.sudirService.getActions(ComponentType.StagesTapeCard);
    if (actions.length) {
      const openGenModalAction = actions.find((act) => act.type === AuthActionType.OpenGenerationModal);
      if (openGenModalAction) {
        setGenerationStage(getStartedStages()?.find((stage) => stage.id === openGenModalAction.args.stageId));
        setGenerationModalOpen(true);
      }

      const redirectAction = actions.find((act) => act.type === AuthActionType.Redirect);
      if (redirectAction) {
        history.push(redirectAction.args.redirectUrl);
      }

      const navigateToVotingAction = actions.find((act) => act.type === AuthActionType.NavigateToVotingPage);
      if (navigateToVotingAction) {
        history.push(navigateToVotingAction.args.redirectUrl);
      }
    }

    return () => {
      setGenerationModalOpen(false);
    };
  }, [stages, isUserReady]);

  useEffect(() => {
    setCardName(getCardName(type));
    setStageName(getStageName(type));
    setFrontDescriptions(getFrontDescriptions(type));
  }, [type]);

  const cardDisabled = (): boolean => !getStartedStages().length;

  const isAllStagesFinished = (): boolean => {
    return stages.length ? stages.every((stage) => itemIsFinished(stage)) : false;
  };

  const isSomeStagesAnnouncement = (): boolean => {
    return stages.some((stage) => itemIsAnnouncement(stage));
  };

  const isSomeStagesCurrent = (): boolean => {
    return stages.some((stage) => itemIsCurrent(stage));
  };

  const isAllStagesNoActive = (): boolean => {
    return stages.every((stage) => !stage.active);
  };

  const getNextStage = (): Stage => {
    const _stages = stages.filter((stage) => itemIsAnnouncement(stage));
    return sortArrByValue<Stage>(_stages, "start")[0];
  };

  const getNextStageDate = (): string => {
    const nexStageDate = getNextStage()?.start;
    return nexStageDate ? dayjs(nexStageDate).locale("ru").format("D MMMM") : "";
  };

  const createFrontDescription = (): string => {
    const { noPlanned, anyCurrent, anyAnnouncement, allFinished, fallbackCase } = currentStageFrontDescriptions;
    if (!stages.length) return noPlanned;
    if (isSomeStagesCurrent()) return anyCurrent;
    if (isSomeStagesAnnouncement()) return `${anyAnnouncement} ${getNextStageDate()}`;
    if (isAllStagesFinished() || isAllStagesNoActive()) return allFinished;
    return fallbackCase;
  };

  const getCardClassNames = (): string => {
    const baseClass = "stage-card";
    let classes = `${baseClass} ${baseClass}_${cardName}`;
    if (cardDisabled()) classes += " disabled";
    return classes;
  };

  const flipCard = (value?) => {
    if (cardInnerRef && !cardDisabled() && canFlipped) {
      cardInnerRef.current.style.transform = value || "rotateY(180deg)";
    }
    if (value) canFlipped = false;
  };

  const forceFlipCard = () => {
    canFlipped = true;
    flipCard();
  };

  const getStartedStages = (): Stage[] => {
    return stages.filter((stage) => stage.status === "STARTED");
  };

  const checkLoggedIn = (ev, stage) => {
    if (stage.type === StageTypes.VOTING && !user.loggedIn) {
      ev.preventDefault();

      const containerData: AuthActionParam = {
        component: ComponentType.StagesTapeCard,
        type: AuthActionType.NavigateToVotingPage,
        args: {
          redirectUrl: `/${cardName}/${stage.id}`,
        },
      };

      const navigateToVotingAction = new AuthAction(containerData);
      return appContext.sudirService.authWithActions([navigateToVotingAction]);
    }
  };

  const proposeIdea = (stage) => {
    if (!user.loggedIn) {
      const containerData: AuthActionParam = {
        component: ComponentType.StagesTapeCard,
        type: isMobile ? AuthActionType.Redirect : AuthActionType.OpenGenerationModal,
        args: {
          stageId: stage.id,
          redirectUrl: `/${cardName}/${stage.id}`,
        },
      };

      const openModalAction = new AuthAction(containerData);
      return appContext.sudirService.authWithActions([openModalAction]);
    }

    if (user.userDetails.teamMember) {
      dispatch(showWarningToast("Вы не можете отправлять идеи, так как представляете команду проекта."));
      return;
    }

    if (isMobile) {
      return history.push(`/${cardName}/${stage.id}`);
    }

    setGenerationStage(stage);
    setGenerationModalOpen(true);
  };

  const getStageTitle = (stage: Stage) => {
    const link =
      stage.type === StageTypes.EXPERT_SELECTION ? (
        <Link className={"stage-card__stage-title"} to={`/select/incoming/${stage.id}`}>{stage.title}</Link>
      ) : (
        <Link className={"stage-card__stage-title"} onClick={(e) => checkLoggedIn(e, stage)} to={`/${cardName}/${stage.id}`}>
          {stage.title}
        </Link>
      );

    const text = <div className={"stage-card__stage-title"}>{stage.title}</div>;

    if (stage.type === "GENERATION") {
      return React.cloneElement(text, {
        onClick: () => proposeIdea(stage),
      });
    } else {
      return link;
    }
  };

  // RENDER

  const renderCountStages = () => (
    <div className="stage-card__back-count">
      {getStartedStages().length} {declination(getStartedStages().length, ["направление", "направления", "направлений"])}
    </div>
  );

  const renderBackStagesList = () => {
    const _stages = getStartedStages();

    const getItemClass = () => (_stages?.length === 1 ? "stage-card__stage-item single-stage-item" : "stage-card__stage-item");

    return (
      <div className="stage-card__stage-list">
        {_stages?.map((stage) => {
          const [_time, _unit] = calculateRemainedTime(stage);
          return (
            <div className={getItemClass()} key={stage.id}>
              <EventsTrackWrapperClick
                id={["CARD", "BUTTON", "CLICK"]}
                needParent
                params={{ replace: [stageName, `${stage.title} ${stageName}`], remember: true }}
              >
                {getStageTitle(stage)}
              </EventsTrackWrapperClick>
              <div className="stage-card__stage-counter">
                {_time} {_unit} до завершения
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const openStageIfSingle = () => {
    const stages = getStartedStages();
    if (stages.length !== 1) return;

    if (!user.loggedIn) {
      let containerData: AuthActionParam = {
        component: ComponentType.StagesTapeCard,
        type: type === "GENERATION" ? AuthActionType.OpenGenerationModal : AuthActionType.Redirect,
        args: {
          stageId: stages[0].id,
          redirectUrl: type === "GENERATION" ? "" : `${cardName}/${stages[0].id}`,
        },
      };

      const action = new AuthAction(containerData);
      return appContext.sudirService.authWithActions([action]);
    }

    if (type === "GENERATION") {
      if (user.userDetails.teamMember) {
        dispatch(showWarningToast("Вы не можете отправлять идеи, так как представляете команду проекта."));
        return;
      }

      if (isMobile) {
        return history.push(`/${cardName}/${stages[0].id}`);
      }

      setGenerationStage(stages[0]);
      setGenerationModalOpen(true);
      return;
    }

    history.push(`/${cardName}/${stages[0].id}`);
  };

  return (
    <div
      className={getCardClassNames()}
      onMouseLeave={() => {
        flipCard("unset");
        canFlipped = true;
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="stage-card__inner" ref={cardInnerRef}>
        <EventsTrackWrapperClick id={["CARD", "CLICK"]} needParent params={{ replace: [stageName] }}>
          <div className="stage-card__front" onMouseOver={() => flipCard()} onClick={isMobile ? forceFlipCard : undefined}>
            <div className={`stage-card__title`}>
              <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: [stageName], remember: true }} needParent>
                <span onClick={openStageIfSingle} className={getStartedStages().length === 1 ? "pointer" : ""}>
                  {stageName}
                </span>
              </EventsTrackWrapperClick>
            </div>
            <div className="stage-card__description">{stagesLoaded && createFrontDescription()}</div>
          </div>
        </EventsTrackWrapperClick>
        {!cardDisabled() && (
          <div className="stage-card__back">
            <EventsTrackWrapperClick id={["CARD", "ICON", "CLICK"]} needParent params={{ replace: [stageName, "Крестик"] }}>
              <i className="stage-card__close ui-icon-close ui-icon" onClick={() => flipCard("unset")} />
            </EventsTrackWrapperClick>
            <EventsTrackWrapperClick
              id={["CARD", "BUTTON", "CLICK"]}
              params={{ replace: [stageName, stageName], remember: true }}
              needParent
            >
              <span onClick={openStageIfSingle} className={`stage-card__back-title ${getStartedStages().length === 1 && "pointer"}`}>
                {stageName}
              </span>
            </EventsTrackWrapperClick>
            {renderCountStages()}
            {renderBackStagesList()}
          </div>
        )}
      </div>

      {generationModalOpen && (
        <GenerationModal
          stage={generationStage}
          key="GenerationModal"
          open={generationModalOpen}
          onStageSelect={(stage) => {
            setGenerationStage(stage);
            setGenerationModalOpen(true);
          }}
          onClose={() => {
            setGenerationModalOpen(false);
          }}
          onFormClose={() => {
            setGenerationModalOpen(false);
          }}
          onStageModalClose={() => {
            setGenerationModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default StagesTapeCard;
