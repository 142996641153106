import { Search } from "@crowd/ui-kit";
import { useEffect, useMemo, useState } from "react";
import { escapeRegExp } from "../../../utils";
import Popup from "../Popup/Popup";
import Table from "../Table/Table";
import { PopupSearchTableProps } from "./PopupSearchTable.interface";
import "./PopupSearchTable.scss";

const PopupSearchTable = <T extends string>({
  isOpen,
  onClose,
  header,
  footer,
  data,
  columns,
  onRowClick,
  activeRow,
  searchPlaceholder,
  bindTo,
  children,
  selectEnabled,
  onSelect,
}: PopupSearchTableProps<T>) => {
  const [searchTag, setSearchTag] = useState("");

  const filteredData = useMemo(
    () => data?.filter((item) => item[bindTo].match(new RegExp(escapeRegExp(searchTag), "i"))),
    [data, searchTag]
  );

  useEffect(() => {
    if (isOpen) {
      setSearchTag("");
      onSelect?.([]);
    }
  }, [isOpen]);

  return (
    <Popup
      className="popup-search-table"
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      footer={footer}
      body={
        <>
          <Search
            placeholder={searchPlaceholder}
            value={searchTag}
            onChange={setSearchTag}
            items={[]}
            icon={<span className="ui-icon ui-icon-search"></span>}
          />

          <Table
            columns={columns}
            data={filteredData}
            onRowClick={onRowClick}
            activeRow={activeRow}
            selectEnabled={selectEnabled}
            onSelect={onSelect}
          />
        </>
      }
    >
      {children}
    </Popup>
  );
};

export default PopupSearchTable;
