import { MutableRefObject, useEffect, useRef } from "react";

export function useOuterClick<T>(
  callback: (e: MouseEvent, innerRef: MutableRefObject<T>) => void
): MutableRefObject<T> {
  const innerRef = useRef<any>();
  const callbackRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }
  }, []);

  return innerRef;
}
