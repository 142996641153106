import { Button, Input } from "@crowd/ui-kit";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProposalService from "../../../../services/proposalService";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { SelectionEditTagFormProps } from "./SelectionEditTagForm.interface";
import "./SelectionEditTagForm.scss";

const SelectionEditTagForm: FC<SelectionEditTagFormProps> = ({ tag, stageId, onSuccess }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(tag.value);
  const [isSaving, setIsSaving] = useState(false);

  const save = async () => {
    setIsSaving(true);
    try {
      const { status, message } = await ProposalService.selectionRenameTag({
        stageId,
        tagId: tag.id,
        newValue: value,
      });

      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      onSuccess?.();
    } catch (e: any) {
      dispatch(showErrorToast(e.message));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setValue(tag.value);
  }, [tag.value]);

  return (
    <div className="selection-edit-tag-form">
      <label>Редактирование группы</label>
      <Input value={value} onChange={setValue} />
      <Button text="Изменить" size="m" type="filled" isDisabled={!value} isLoading={isSaving} onClick={save} />
    </div>
  );
};

export default SelectionEditTagForm;
