import { AttachmentPreview, Button } from "@crowd/ui-kit";
import dayjs from "dayjs";
import { FC, useState } from "react";
import ClosingCard from "../../../ClosingCard/ClosingCard";
import { proposalStatusColorMap } from "../../ProposalCard.constants";
import { ProposalHistoryCardProps } from "./ProposalHistoryCard.interface";
import "./ProposalHistoryCard.scss";

const ProposalHistoryCard: FC<ProposalHistoryCardProps> = ({ proposal }) => {
  const [showFull, setShowFull] = useState(false);

  return (
    <div className={`proposal-history-card ${!showFull ? "proposal-history-card_short" : ""}`}>
      <div className="proposal-history-card__top">
        <span className="proposal-history-card__date">
          {dayjs(proposal.lastSubmitted || proposal.lastEdited || proposal.submissionDate).format("DD.MM.YYYY HH:mm")}
        </span>
        <span className="proposal-history-card__status" style={{ color: proposalStatusColorMap[proposal.status] }}>
          {proposal.statusTitle}
        </span>
      </div>
      <div className="proposal-history-card__title">{proposal.title}</div>
      <ol className={`proposal-history-card__list ${proposal.items.length === 1 ? "proposal-history-card__list_alone" : ""}`}>
        {proposal.items.map(({ orderNumber, valueRaw }) => (
          <li key={orderNumber}>
            <span dangerouslySetInnerHTML={{ __html: valueRaw }}></span>
          </li>
        ))}
      </ol>
      {!!proposal.attachments?.length && (
        <div className="proposal-history-card__attachments">
          {proposal.attachments.map((attachment) => (
            <AttachmentPreview key={attachment.id} attachment={attachment} />
          ))}
        </div>
      )}
      {!!proposal.reason && (
        <ClosingCard className="proposal-history-card__reason-block" isOpened isSmall type="gray">
          <label>Причина отклонения</label>
          <div className="break-word">{proposal.reason}</div>
          {!!proposal.rejectComment && (
            <>
              <label>Комментарий</label>
              <div className="break-word">{proposal.rejectComment}</div>
            </>
          )}
        </ClosingCard>
      )}
      <Button
        classNames="proposal-history-card__collapse-btn"
        type="text-button"
        text={showFull ? "Свернуть" : "Развернуть"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowFull((prev) => !prev);
        }}
        icon={showFull ? "ui-icon-chevron-up" : "ui-icon-chevron-down"}
      />
    </div>
  );
};

export default ProposalHistoryCard;
