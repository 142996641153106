import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CommentService from "../../../../services/commentService";
import { showMosruForm } from "../../../../store/actions/LayoutActions";
import { showErrorToast } from "../../../../store/actions/ToastActions";

import useQuery from "../../../../utils/hooks/useQuery";
import { removeUrlParam } from "../../../../utils/urlUtils";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import FadeIn from "../../../presentational/FadeIn/FadeIn";
import CommentListLoader from "../../../presentational/Loaders/CommentListLoader/CommentListLoader";
import Pagination from "../../../presentational/Pagination/Pagination";
import "./CommentCardList.scss";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { RootState } from "../../../../types/State.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { selectIsUserReady } from "../../../../store/reducers/profile";

export default function CommentCardList({ post, ...props }) {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const params: any = useParams();
  const query = useQuery();
  const history = useHistory();
  const location: any = useLocation();

  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(null);
  const [paging, setPaging] = useState(null);
  const [page, setPage] = useState(null);
  const [navigationRootId, setNavigationRootId] = useState(null);
  const [navigationCommentId, setNavigationCommentId] = useState(null);
  const isUserReady = useSelector(selectIsUserReady);
  const [dataForReadyUserLoaded, setDataForReadyUserLoaded] = useState(false);

  const elementsRef = React.useRef({});
  const scrollToRef = React.useRef<null | HTMLDivElement>(null);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!comments?.length || !dataForReadyUserLoaded) return;

    const actions = appContext.sudirService.getActions(ComponentType.CommentCardList);
    if (actions.length) {
      const openCommentFormAction = actions.find((act) => act.type === AuthActionType.OpenCommentForm);
      if (openCommentFormAction) {
        const commentId = openCommentFormAction.args.commentId;
        setNavigationCommentId(commentId);
        setTimeout(() => {
          onShowForm({ id: commentId });
        }, 500)
      }
    }
  }, [comments, dataForReadyUserLoaded]);

  useEffect(() => {
    resolvePage();
  }, []);

  useEffect(() => {
    (page || page === 0) && loadData(page);
  }, [page, isUserReady]);

  useEffect(() => {
    if (location.state?.updateFirstPage) {
      setPage(0);
      loadData(0);
    }
  }, [location.state]);

  const resolvePage = async () => {
    let page;

    if (params?.commentId) {
      setLoading(true);
      try {
        const { data, paging } = await CommentService.getById(params?.commentId);

        clearPath(params.commentId);

        if (query.navigate) {
          setNavigation(data, params.commentId);
        }

        setPaging(paging);
        setPage(paging.pageNumber);
      } catch (err: any) {
        showErrorToast(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      page = Number.parseInt(query.page) - 1 || 0;
      setPage(page);
    }
  };

  const clearPath = (commentId) => {
    history.replace({
      pathname: location.pathname.replace(`/${commentId}`, ""),
      search: location.search,
    });
  };

  //OPEN FORM
  useEffect(() => {
    if (navigationCommentId && comments) {
      const { openForm } = query as any;
      if (openForm) {
        setTimeout(() => {
          const element = elementsRef.current[navigationCommentId];
          if (element) {
            element.openForm(true);
            removeUrlParam("openForm", history);
          }
        }, 500);
      }
    }
  }, [navigationCommentId, comments]);

  //NAVIGATE
  useEffect(() => {
    if (navigationCommentId && comments) {
      setNavigationCommentId(null);
      removeUrlParam("navigate", history);
      setTimeout(() => {
        const rootElement = document.getElementById(navigationCommentId);
        if (rootElement) {
          const formElement = rootElement.querySelector(".add-comment");
          const wrapperElement = rootElement.querySelector(".comment-card__wrapper");

          if (formElement || wrapperElement) {
            if (formElement) {
              scrollToElement(formElement);
            } else {
              scrollToElement(wrapperElement);
            }
            highlightElement(wrapperElement);
          }
        }
      }, 1000);
    }
  }, [navigationCommentId, navigationRootId, comments]);

  function loadAnswers(comments) {
    const ids = comments.map((comment) => comment.id);
    return CommentService.loadResponsesByCommentIDs({
      ids: ids,
      amount: 100,
    });
  }

  function loadAnswer(comment) {
    return CommentService.loadResponses({
      commentId: comment.id,
      amount: 100,
    });
  }

  function loadComments(page) {
    const params: any = {
      discussionId: post?.discussionId,
      size: props.showPerPage,
      page: page,
    };

    return CommentService.get(params);
  }

  async function loadData(page) {
    setLoading(true);
    try {
      const { data: commentsWithoutAnsw, paging, status, message } = await loadComments(page);

      if (status !== ApiStatusCode.OK) {
        throw message || status;
      }

      const answersResult = await loadAnswers(commentsWithoutAnsw || []);

      const commentsWithAnswers = (commentsWithoutAnsw || []).map((comment) => {
        const answers = answersResult.data.find((c) => c.commentId === comment.id);
        comment.responses = answers?.comments?.reverse() || [];
        return comment;
      });

      if (location.state?.updateFirstPage) {
        setNavigation(commentsWithAnswers, commentsWithAnswers[0].id);
        history.replace({
          state: { updateFirstPage: false },
        });
      }

      setComments(commentsWithAnswers || []);
      setPaging(paging);
    } catch (err: any) {
      setComments([]);
      dispatch(showErrorToast(err.message));
    } finally {
      setDataForReadyUserLoaded(isUserReady);
      setLoading(false);
    }
  }

  const getRootComment = (comments, commentId) => {
    return comments.find((r) => r.id === commentId) || comments.find((r) => r.responses.find((r) => r.id === commentId));
  };

  const setNavigation = (comments, commentId) => {
    setNavigationRootId(getRootComment(comments, commentId)?.id);
    setNavigationCommentId(commentId);
  };

  const scrollToElement = (element) => {
    if (isPhone) {
      element.scrollIntoView(false);
    } else if (isTablet) {
      const stickyOffsetHeight = 124;
      const headerHeight = 64;
      const y = element.getBoundingClientRect().top + window.pageYOffset - stickyOffsetHeight - headerHeight - 5;
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 0);
    } else {
      setTimeout(() => {
        element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }, 0)
    }
  };

  const highlightElement = (element) => {
    element.classList.add("comment-card--highlight");
    setTimeout(() => {
      element?.classList.remove("comment-card--highlight");
    }, 1500);
  };

  const onUpdate = async (comment) => {
    let _comments = [...comments];
    let _parent = _comments.find((c) => c.id === comment.rootId);
    setNavigationRootId(null);

    const result = await loadAnswer(_parent);

    _parent.responses = result.data.comments?.reverse();
    setComments(_comments);
    setNavigation(_comments, comment.id);
  };

  const onOutputAPI = (entity) => {
    elementsRef.current[entity.id] = entity.api;
  };

  const onShowForm = (comment) => {
    const commentControl = elementsRef.current[comment.id];
    if (!loggedIn) {
      let containerData: AuthActionParam = {
        component: ComponentType.CommentCardList,
        type: AuthActionType.OpenCommentForm,
        args: {
          commentId: comment.id,
        },
      };

      const action = new AuthAction(containerData);
      return appContext.sudirService.authWithActions([action]);
    }

    commentControl.openForm(true);
  };

  // RENDER

  const renderNoComments = () => {
    return <div className={"comment-card-list__empty"}>{props.emptyText || "На текущий момент нет ни одного комментария"}</div>;
  };

  const renderComments = () => {
    if (!comments) return;
    if (comments.length === 0) return renderNoComments();

    return (
      <React.Fragment>
        {comments.map((comment) => (
          <CommentCard
            onOutputAPI={onOutputAPI}
            key={comment.id}
            commentInfoHintType={"SHORT"}
            comment={comment}
            canShowReplyButton={post.active}
            addComment={props.addComment}
            loadAnswers={false}
            showAnswers={comment.id === navigationRootId}
            comments={comments}
            onShowForm={onShowForm}
            onAddComment={onUpdate}
            onUpdateComment={() => loadData(page)}
            onRemoveComment={() => loadData(page)}
            externalNavigateId={navigationCommentId}
            formNavigationLinkFn={props.formNavigationLinkFn}
            // onClick={(e)=>scrollToElement(e.target)}
          />
        ))}

        {paging && (
          <Pagination
            totalPages={paging.totalPages}
            onPageChange={({ selected }) => {
              setPage(selected);
              if (scrollToRef.current) scrollToRef.current.scrollIntoView();
            }}
            currentPage={page}
            pageName="page"
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="comment-card-list" ref={scrollToRef}>
      <div className="comment-card-list__title">Комментарии и ответы</div>
      {loading ? <CommentListLoader /> : <FadeIn>{renderComments()}</FadeIn>}
    </div>
  );
}
