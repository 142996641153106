import { FC } from "react";
import { FilterButtonsProps } from "./FilterButtons.types";
import "./FilterButtons.scss";
import { Button } from "@crowd/ui-kit";

const FilterButtons: FC<FilterButtonsProps> = ({ buttonsData, className }) => {
  return (
    !!buttonsData.length && (
      <div className={`filter-buttons ${className || ""}`}>
        {buttonsData.map(({ text, count, isActive, onClick }, i) => (
          <Button
            key={i}
            text={`${text}${typeof count === "undefined" ? "" : ` ${count}`}`}
            type={isActive ? "filled" : "outlined"}
            onClick={onClick}
          />
        ))}
      </div>
    )
  );
};

export default FilterButtons;
