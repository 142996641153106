import { Button, Loader, Modal, Search, Select } from "@crowd/ui-kit";
import dayjs from "dayjs";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppColorsEnum } from "../../../App.interface";
import ProposalService from "../../../services/proposalService";
import StageService from "../../../services/stageService";
import { setAppColor, showConfirmInfoModal, showErrorInfoModal, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { ApiStatusCode, PagingResponseParameters } from "../../../types/Common.interface";
import {
  CountedProposalsByStatus,
  GetSelectionRequest,
  ProposalInterface,
  ProposalTag,
  ProposalTypeEnum,
} from "../../../types/Proposal.interface";
import { Stage } from "../../../types/Stage.interface";
import { RootState } from "../../../types/State.interface";
import { calculateRemainedTime, declOfNum, getLocalDate, getPageFromUrl, scrollToTop } from "../../../utils";
import { SelectionFolders, SelectionFoldersNames, SelectionProposalCounters, folderToCounterMap } from "../../../utils/constants/proposal";
import ClosingCard from "../../presentational/Cards/ClosingCard/ClosingCard";
import FilterButtons from "../../presentational/Controls/FilterButtons/FilterButtons";
import { FilterButtonsProps } from "../../presentational/Controls/FilterButtons/FilterButtons.types";
import Expander from "../../presentational/Expander/Expander";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import Tooltip from "../../presentational/Tooltip/Tooltip";
import UserInfo from "../../presentational/UserInfo/UserInfo";
import "./Selection.scss";
import IdeaTransferHistoryModal from "./IdeaTransferHistoryModal/IdeaTransferHistoryModal";
import SelectionIdeaInfo from "./SelectionIdeaInfo/SelectionIdeaInfo";
import SelectionAcceptForm from "./SelectionAcceptForm/SelectionAcceptForm";
import SelectionEditTagForm from "./SelectionEditTagForm/SelectionEditTagForm";
import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";
import PopupSearchTable from "../../presentational/PopupSearchTable/PopupSearchTable";
import { TableProps } from "../../presentational/Table/Table";
import SelectMainIdeaModal from "./SelectMainIdeaModal/SelectMainIdeaModal";
import DeclineProposalForm from "./DeclineProposalForm/DeclineProposalForm";
import DatePicker from "../../presentational/Controls/DatePicker/DatePicker";
import UserService from "../../../services/userService";
import { DatePickerType } from "../../presentational/Controls/DatePicker/DatePicker.interface";
import Switcher from "../../presentational/Controls/Switcher/Switcher";
import SelectionForwardForm from "./SelectionForwardForm/SelectionForwardForm";
import { User } from "../../../types/User.interface";
import SelectionMoveForm from "./SelectionMoveForm/SelectionMoveForm";
import SelectionInfo from "./SelectionInfo/SelectionInfo";
import { showErrorToast, showSuccessToast } from "../../../store/actions/ToastActions";
import { genderizeWord } from "../../../utils/user.utils";
import MobileStubModal from "../../containers/Modals/MobileStubModal/MobileStubModal";
import { useMediaQuery } from "react-responsive";
import { shortDesktopWidth } from "../../../utils/constants/widthConstants";
import Pagination from "../../presentational/Pagination/Pagination";

interface IdeaControlParam {
  isActive?: (idea: ProposalInterface) => boolean;
  isDisabled?: (idea: ProposalInterface) => boolean;
  isHidden?: (idea: ProposalInterface) => boolean;
  tooltipText: string;
  icon: string;
  labelText?: string;
  action: (idea: ProposalInterface) => void;
}

type SelectFilterType = { value: string; text: string }[];

const tagsColumns: TableProps<keyof ProposalTag>["columns"] = [
  {
    header: "Название",
    accessor: "value",
    renderValue: ({ value }) => <span className="selection-page__table-primary">{value}</span>,
    sortEnabled: true,
  },
  { header: <span className="ui-icon ui-icon-idea" />, accessor: "proposalCount", sortEnabled: true, className: "text-center" },
  {
    header: <span className="ui-icon ui-icon-sports_score_black_24dp" />,
    accessor: "finalized",
    renderValue: ({ value }) => value && <span className="ui-icon ui-icon-done-symbol selection-page__table-icon" />,
    sortEnabled: true,
  },
  {
    header: "Автор главной идеи",
    accessor: "mainProposalAuthor",
    renderValue: ({ value }) => `${value.firstName} ${value.lastName}`,
    sortEnabled: true,
  },
];

const filterTypesSearchSelect: SelectFilterType = [
  { value: "title", text: "По заголовку" },
  { value: "text", text: "По тексту" },
  { value: "authorId", text: "По автору" },
  { value: "expertId", text: "По эксперту" },
];
const filterFieldsSearchSelect = filterTypesSearchSelect.map(({ value }) => value);

const getCleanedTypeSearchFilter = (filter) =>
  Object.fromEntries(Object.entries(filter).filter(([k]) => !filterFieldsSearchSelect.includes(k)));

// 20 минут на обработку идеи во входящих
const reservationPeriodSec = 1200;

const Selection: FC = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${shortDesktopWidth}px)` });
  const history = useHistory();
  const { folder, stageId } = useParams<{ folder: SelectionFolders; stageId: string }>();
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.current);
  const { chiefExpert, id: currentUserId } = useSelector((state: RootState) => state.user.userDetails);

  const [stage, setStage] = useState<Stage>();
  const [generationStage, setGenerationStage] = useState<Stage>();
  const [countProposals, setCountProposals] = useState<CountedProposalsByStatus>();
  const [remained, setRemained] = useState("");
  const [stageFinishIsComing, setStageFinishIsComing] = useState(false);
  const [isStageFinished, setIsStageFinished] = useState(false);
  const [ideas, setIdeas] = useState<ProposalInterface[]>();
  const [reservationExpiredModalOpen, setReservationExpiredModalOpen] = useState(false);
  const [tags, setTags] = useState<ProposalTag[]>();
  const tagsNormalized = useMemo(
    () =>
      tags?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: curr,
        }),
        {}
      ),
    [tags]
  );
  const [experts, setExperts] = useState<User[]>();
  const expertsList = useMemo(
    () => experts?.map(({ firstName, lastName, id }) => ({ value: id, text: `${firstName} ${lastName}` })) || [],
    [experts]
  );
  const [rejectReasons, setRejectReasons] = useState(null);
  const [ideaProcess, setIdeaProcess] = useState<ProposalInterface>();
  const [ideaProcessType, setIdeaProcessType] = useState<"accepting" | "declining" | "forwarding" | "moving" | "tagEditing">();
  const [ideaShowTransferHistory, setIdeaShowTransferHistory] = useState<ProposalInterface>();
  const [tagsFilterOpened, setTagsFilterOpened] = useState(false);
  const [tagsSelectCombiningOpened, setTagsSelectCombiningOpened] = useState(false);
  const [filter, setFilter] = useState<Omit<GetSelectionRequest, "stageId">>(() =>
    Object.fromEntries(
      Object.entries(Object.fromEntries(new URLSearchParams(history.location.search))).map(([k, v]) => [
        k,
        v === "true" ? true : v === "false" ? false : v,
      ])
    )
  );
  const [filterTypeSearch, setFilterTypeSearch] = useState(
    filterTypesSearchSelect.find(({ value }) => value in filter) || filterTypesSearchSelect[0]
  );
  const [seachFilterValue, setSeachFilterValue] = useState("");
  const [filterSearchRes, setFilterSearchRes] = useState<SelectFilterType>([]);
  const tagFilter = useMemo<ProposalTag>(() => tags?.find(({ id }) => id === filter.tagId), [tags, filter.tagId]);
  const tagsForCombining = useMemo(() => (tagFilter ? tags.filter((tag) => tag !== tagFilter) : tags), [tags, tagFilter]);
  const [selectedTagsForCombining, setSelectedTagsForCombining] = useState<ProposalTag[]>([]);
  const [ideasForCombining, setIdeasForCombining] = useState<ProposalInterface[]>();
  const [isTagEditing, setIsTagEditing] = useState(false);
  const [isCombiningTags, setIsCombiningTags] = useState(false);

  const isLoading = !ideas || !stage || !countProposals;
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState<PagingResponseParameters>(null);
  const [page, setPage] = useState(getPageFromUrl(history) || 0);

  const counts = {
    [SelectionProposalCounters.SUBMITTED]: countProposals?.submitted,
    [SelectionProposalCounters.ACCEPTED]: countProposals?.accepted,
    [SelectionProposalCounters.REJECTED]: countProposals?.rejected,
    [SelectionProposalCounters.FORWARDED]: countProposals?.forwarded,
    [SelectionProposalCounters.FORWARDED_TOTAL]: countProposals?.forwardedTotal,
    [SelectionProposalCounters.UNREVIEWED]: countProposals?.forwardedTotal + countProposals?.submitted,
  };

  const fetchStage = () => {
    StageService.getStages(stageId).then((data) => setStage(data.data));
  };
  const fetchGenerationStage = () => {
    StageService.getGenerationStageBySelectionStage(stageId).then((data) => setGenerationStage(data.data));
  };
  const fetchTags = () => {
    ProposalService.getTags(stageId).then((data) => setTags(data.data));
  };
  const fetchCounts = () => {
    ProposalService.getSelectionCount(stageId).then((data) => setCountProposals(data.data));
  };
  const fetchRejectReasons = () => {
    ProposalService.getRejectReasons(stageId).then((data) => setRejectReasons(data.data));
  };
  const fetchExperts = () => {
    UserService.findUser({ type: "EXPERT", size: 100 }).then((data) => setExperts(data.data));
  };

  const getFilterButtonsData: () => FilterButtonsProps["buttonsData"] = () => {
    return Object.values(SelectionFolders).map((f) => ({
      text: SelectionFoldersNames[f],
      count: counts[folderToCounterMap[f]],
      isActive: folder === f,
      onClick: () => {
        setPage(0);
        folder !== f && history.push(`/select/${f}/${stageId}`);
      },
    }));
  };

  const fetchIncoming = (proposalId?: string) => {
    setIsFetching(true);
    ProposalService.getSelectionIncoming(stageId, proposalId)
      .then((data) => {
        switch (data.status) {
          case ApiStatusCode.ILLEGAL_INPUT:
            throw new Error(data.message);
          case ApiStatusCode.OK:
            setIdeas([data.data]);
            break;
          default:
            setIdeas([]);
            break;
        }
        setPagination(null);
      })
      .catch((e) => {
        dispatch(showErrorToast(e.message));
      })
      .finally(() => setIsFetching(false));
  };

  const fetchAccepted = () => {
    setIsFetching(true);
    ProposalService.getSelectionAccepted({
      stageId,
      ...filter,
      size: 20,
      page: page,
    })
      .then((data) => {
        if (data.status === ApiStatusCode.OK) {
          setIdeas(data.data);
          setPagination(data.paging);
          currectPagination(data.paging.totalPages);
        }
      })
      .catch(console.log)
      .finally(() => setIsFetching(false));
  };

  const fetchRejected = () => {
    setIsFetching(true);
    ProposalService.getSelectionRejected({
      stageId,
      ...filter,
      size: 20,
      page: page,
    })
      .then((data) => {
        if (data.status === ApiStatusCode.OK) {
          setIdeas(data.data);
          setPagination(data.paging);
          currectPagination(data.paging.totalPages);
        }
      })
      .catch(console.log)
      .finally(() => setIsFetching(false));
  };

  const fetchForwarded = () => {
    setIsFetching(true);
    ProposalService.getSelectionForwarded({
      stageId,
      ...filter,
      expertId: currentUserId,
      size: 20,
      page: page,
    })
      .then((data) => {
        if (data.status === ApiStatusCode.OK) {
          setIdeas(data.data);
          setPagination(data.paging);
          currectPagination(data.paging.totalPages);
        }
      })
      .catch(console.log)
      .finally(() => setIsFetching(false));
  };

  const currectPagination = (newPageCount) => {
    if (newPageCount === 0) return;
    if (newPageCount < page + 1) setPage(newPageCount - 1);
  };

  const nextIdea = (idea) => {
    fetchIncoming(idea.id);
  };

  const fetchIdeas = () => {
    switch (folder) {
      case SelectionFolders.INCOMING:
        fetchIncoming();
        break;

      case SelectionFolders.ACCEPTED:
        fetchAccepted();
        break;

      case SelectionFolders.REJECTED:
        fetchRejected();
        break;

      case SelectionFolders.FORWARDED:
        fetchForwarded();
        break;
    }
  };

  const setMainIdea = (idea: ProposalInterface) => {
    dispatch(
      showConfirmInfoModal("Вы действительно хотите сделать идею главной в группе?", async () => {
        try {
          const { status, message } = await ProposalService.selectionSetMain({ stageId, proposalId: idea.id });
          if (status !== ApiStatusCode.OK) {
            throw new Error(message);
          }
          fetchIdeas();
        } catch (e: any) {
          dispatch(showErrorInfoModal(e.message));
        }
      })
    );
  };

  const unite = async () => {
    setTagsSelectCombiningOpened(false);
    try {
      const data = await Promise.all(
        [tagFilter, ...selectedTagsForCombining].map(async (tag) => ProposalService.selectionGetMainProposal(stageId, tag.value))
      );
      const fail = data.find(({ status }) => status !== ApiStatusCode.OK);
      if (fail) {
        throw new Error(fail.message);
      }
      setIdeasForCombining(data.map(({ data }) => data));
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    }
  };

  const canFinalizeAll = useMemo(
    () =>
      chiefExpert &&
      countProposals &&
      !countProposals.submitted &&
      !countProposals.forwardedTotal &&
      generationStage?.status === "FINISHED" &&
      tags?.some(({ finalized }) => !finalized),
    [counts[SelectionProposalCounters.SUBMITTED], counts[SelectionFolders.FORWARDED], generationStage?.status, tags, chiefExpert]
  );
  const canUnfinafileAll = useMemo(
    () => chiefExpert && !stage?.finishConfirmed && !!tags?.length && tags.every(({ finalized }) => finalized),
    [tags, stage?.finishConfirmed, chiefExpert]
  );

  const finalize = (tagId?: string) => {
    dispatch(
      showConfirmInfoModal(
        tagId
          ? "При финализации группы идей, назначенная главной в группе, будет зафиксирована и перейдет на следующий этап. Продолжить?"
          : "Вы действительно хотите финализировать все группы?",
        async () => {
          try {
            const { status, message } = await ProposalService.selectionFinalize(stageId, tagId);
            if (status !== ApiStatusCode.OK) {
              throw new Error(message);
            }
            fetchStage();
            fetchTags();
            if (!tagId) {
              dispatch(showSuccessToast("Все группы финализированы"));
            }
          } catch (e: any) {
            dispatch(showErrorInfoModal(e.message));
          }
        }
      )
    );
  };

  const unfinalize = (tagId?: string) => {
    dispatch(
      showConfirmInfoModal(tagId ? "" : "Вы действительно хотите отменить финализацию всех групп?", async () => {
        try {
          const { status, message } = await ProposalService.selectionUnfinalize(stageId, tagId);
          if (status !== ApiStatusCode.OK) {
            throw new Error(message);
          }
          fetchStage();
          fetchTags();
          if (!tagId) {
            dispatch(showSuccessToast("Финализация всех групп отменена"));
          }
        } catch (e: any) {
          dispatch(showErrorInfoModal(e.message));
        }
      })
    );
  };

  const onConfirmSelectedMainIdea = async (selectedIdea: ProposalInterface) => {
    setIsCombiningTags(true);
    try {
      const { status, message } = await ProposalService.selectionTagUnite({
        stageId,
        targetTagId: tagFilter.id,
        sourceTagIds: selectedTagsForCombining.map(({ id }) => id),
        mainProposalId: selectedIdea.id,
      });
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }
      setIdeasForCombining(undefined);
      fetchTags();
      fetchIdeas();
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    } finally {
      setIsCombiningTags(false);
    }
  };

  const prevTag = () => {
    if (tagFilter) {
      const i = tags.indexOf(tagFilter);
      const pi = i >= 1 ? i - 1 : tags.length - 1;
      setFilter((prev) => ({ ...prev, tagId: tags[pi].id }));
    }
  };

  const nextTag = () => {
    if (tagFilter) {
      const i = tags.indexOf(tagFilter);
      const ni = i < tags.length - 1 ? i + 1 : 0;
      setFilter((prev) => ({ ...prev, tagId: tags[ni].id }));
    }
  };

  const onItemFilterTypeSelect = (value) => {
    setFilterTypeSearch(value);
    setSeachFilterValue("");
    setFilterSearchRes([]);
  };

  const onChangeSearchFilter = (value) => {
    setSeachFilterValue(value);
    if (value) {
      switch (filterTypeSearch.value) {
        case "title":
        case "text":
          setFilter((prev) => ({
            ...getCleanedTypeSearchFilter(prev),
            [filterTypeSearch.value]: value,
          }));
          break;

        case "authorId":
          UserService.findUser({ type: "PARTICIPANT", query: value }).then(({ data }) =>
            setFilterSearchRes(data?.map(({ firstName, lastName, id }) => ({ value: id, text: `${firstName} ${lastName}` })) || [])
          );
          break;
        case "expertId":
          const r = new RegExp(value, "i");
          setFilterSearchRes(expertsList?.filter(({ text }) => text.match(r)));
          break;
      }
    } else {
      setFilter((prev) => getCleanedTypeSearchFilter(prev));
    }
  };

  const onItemFilterSearchSelect = ({ value, text }) => {
    setSeachFilterValue(text);
    setFilter((prev) => ({
      ...getCleanedTypeSearchFilter(prev),
      [filterTypeSearch.value]: value,
    }));
  };

  const onEditTagSuccess = () => {
    fetchTags();
    fetchIdeas();
    setIsTagEditing(false);
  };

  const ideaControlParams = useMemo<IdeaControlParam[]>(() => {
    return [
      {
        isActive: (idea) => idea.status === ProposalTypeEnum.ACCEPTED || (ideaProcess === idea && ideaProcessType === "accepting"),
        isDisabled: (idea) =>
          ![ProposalTypeEnum.SUBMITTED, ProposalTypeEnum.FORWARDED].includes(idea.status) &&
          !(chiefExpert && ProposalTypeEnum.REJECTED === idea.status),
        action: (idea) => {
          setIdeaProcess(idea);
          setIdeaProcessType("accepting");
        },
        tooltipText: "Принять",
        labelText: "Принята",
        icon: "ui-icon-done-symbol",
      },
      {
        isActive: (idea) => idea.status === ProposalTypeEnum.REJECTED || (ideaProcess === idea && ideaProcessType === "declining"),
        isDisabled: (idea) =>
          ![ProposalTypeEnum.SUBMITTED, ProposalTypeEnum.FORWARDED].includes(idea.status) &&
          !(chiefExpert && ProposalTypeEnum.ACCEPTED === idea.status),
        action: (idea) => {
          setIdeaProcess(idea);
          setIdeaProcessType("declining");
        },
        tooltipText: "Отклонить",
        labelText: "Отклонена",
        icon: "ui-icon-remove-round",
      },
      {
        isActive: (idea) => idea.status === ProposalTypeEnum.FORWARDED || (ideaProcess === idea && ideaProcessType === "forwarding"),
        isDisabled: (idea) => ![ProposalTypeEnum.SUBMITTED].includes(idea.status),
        action: (idea) => {
          setIdeaProcess(idea);
          setIdeaProcessType("forwarding");
        },
        tooltipText: "Передать",
        labelText: "Передана",
        icon: "ui-icon-send-message",
      },
      {
        isActive: (idea) => ideaProcess === idea && ideaProcessType === "moving",
        isDisabled: (idea) => ![ProposalTypeEnum.SUBMITTED, ProposalTypeEnum.FORWARDED].includes(idea.status) && !chiefExpert,
        action: (idea) => {
          setIdeaProcess(idea);
          setIdeaProcessType("moving");
        },
        tooltipText: "Переместить",
        labelText: "Перемещена",
        icon: "ui-icon-drive-file-move",
      },
      {
        isHidden: (idea) => !chiefExpert || idea.selectedMain || ![ProposalTypeEnum.ACCEPTED].includes(idea.status),
        action: setMainIdea,
        tooltipText: "Выбрать главной",
        icon: "ui-icon-Component4073",
      },
      {
        isHidden: (idea) => ![ProposalTypeEnum.SUBMITTED].includes(idea.status),
        action: nextIdea,
        tooltipText: "Пропустить",
        icon: "ui-icon-play-next",
      },
    ];
  }, [stageId, ideaProcess, ideaProcessType, folder, filter, currentUserId]);

  const ideasExperts = useMemo(
    () =>
      ideas?.reduce((prev, curr) => {
        prev[curr.id] =
          curr.status === ProposalTypeEnum.FORWARDED
            ? curr.statusHistory
                ?.slice()
                .reverse()
                .find((item) => item.status === ProposalTypeEnum.FORWARDED)?.forwardedBy
            : curr.expert ||
              curr.statusHistory
                ?.slice()
                .reverse()
                .find((item) => item.user.expert)?.user;
        return prev;
      }, {}) || {},
    [ideas]
  );

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());

    if (["authorId", "expertId"].includes(filterTypeSearch.value) && filter[filterTypeSearch.value]) {
      UserService.getPerson(filter[filterTypeSearch.value]).then(({ data }) => setSeachFilterValue(`${data.firstName} ${data.lastName}`));
    } else {
      setSeachFilterValue(filter[filterTypeSearch.value] || "");
    }

    fetchExperts();
  }, []);

  useEffect(() => {
    fetchStage();
    fetchGenerationStage();
    fetchCounts();
    fetchTags();
    fetchRejectReasons();
  }, [stageId]);

  useUpdateEffect(() => {
    setFilter({});
    setIdeaProcess(undefined);
  }, [stageId, folder]);

  useEffect(() => {
    const params = new URLSearchParams(filter as any);
    const search = params ? `?${params}` : "";
    if (history.location.search !== search) {
      history.push(`${history.location.pathname}${search}`);
    }
  }, [filter]);

  useUpdateEffect(() => {
    setIdeaProcess(undefined);
  }, [ideas]);

  useEffect(() => {
    const tid = setTimeout(fetchIdeas, 100);
    return () => clearTimeout(tid);
  }, [folder, stageId, filter, page]);

  useEffect(() => {
    if (!stage) {
      return;
    }

    const _setRemained = () => {
      if (dayjs(stage.finish) < dayjs()) {
        setRemained("Этап завершен");
        setIsStageFinished(true);
        setStageFinishIsComing(false);
        return;
      }

      const res = calculateRemainedTime(stage);
      res ? setRemained(`${res[0]} ${res[1]} до завершения`) : setRemained("");
      setStageFinishIsComing(dayjs(stage.finish) < dayjs().add(1, "day"));
    };

    _setRemained();

    const tid = setInterval(_setRemained, 60000);

    return () => clearInterval(tid);
  }, [stage]);

  useEffect(() => {
    setIsTagEditing(false);
  }, [filter.tagId, folder]);

  // отсчет времени резервации идеи
  useEffect(() => {
    if (folder === SelectionFolders.INCOMING && ideas?.[0]?.reservation?.timestamp) {
      const reservationExpired = dayjs(ideas[0].reservation.timestamp).add(reservationPeriodSec, "second");
      const tid = setInterval(() => {
        if (dayjs().diff(reservationExpired) >= 0) {
          setReservationExpiredModalOpen(true);
        }
      }, 1000);

      return () => clearInterval(tid);
    }
  }, [ideas, folder]);

  const renderMainContent = () => {
    if (isStageFinished) return null;

    return (
      <>
        <div className="selection-page__block-indent flex">
          <div className="flex-fill">
            <FilterButtons buttonsData={getFilterButtonsData()} />
            {folder === SelectionFolders.INCOMING || folder === SelectionFolders.FORWARDED ? (
              <Expander
                className="selection-page__block-indent"
                title="Прочитайте критерии отбора идей"
                titleIcon="ui-icon-warning"
                hiddenElement={<span dangerouslySetInnerHTML={{ __html: project.criteria }} />}
              />
            ) : (
              <div className="selection-page__block-indent">
                {folder === SelectionFolders.ACCEPTED && (
                  <div className="selection-page__tag">
                    <PopupSearchTable
                      isOpen={tagsFilterOpened}
                      onClose={() => setTagsFilterOpened(false)}
                      header={
                        <span
                          className={!!tagFilter ? "pointer" : ""}
                          onClick={
                            !!tagFilter
                              ? () => {
                                  setFilter((prev) => Object.fromEntries(Object.entries(prev).filter(([k]) => k !== "tagId")));
                                  setTagsFilterOpened(false);
                                }
                              : undefined
                          }
                        >
                          Все группы ({tags?.length})
                        </span>
                      }
                      columns={tagsColumns}
                      data={tags}
                      onRowClick={(tag) => {
                        setFilter((prev) => ({ ...prev, tagId: tag.id }));
                        setTagsFilterOpened(false);
                      }}
                      activeRow={tagFilter}
                      bindTo="value"
                      searchPlaceholder="Поиск группы"
                    >
                      <Button
                        classNames="selection-page__tag-name"
                        text={!!tagFilter ? tagFilter.value : "Все группы"}
                        type="text-button"
                        onClick={() => setTagsFilterOpened(true)}
                      />
                    </PopupSearchTable>{" "}
                    {tagFilter ? (
                      <>
                        {chiefExpert && (
                          <>
                            <Tooltip
                              className={`selection-page__tag-control ${isTagEditing ? "selection-page__tag-control_active" : ""}`}
                              idx="selectop-tag-edit"
                              text="Редактировать"
                            >
                              <Button type="text-button" text="" icon="ui-icon ui-icon-edit" onClick={() => setIsTagEditing(true)} />
                            </Tooltip>
                            <PopupSearchTable<keyof ProposalTag>
                              isOpen={tagsSelectCombiningOpened}
                              onClose={() => setTagsSelectCombiningOpened(false)}
                              header="Объединить с группой"
                              columns={tagsColumns}
                              data={tagsForCombining}
                              searchPlaceholder="Поиск группы"
                              bindTo="value"
                              selectEnabled
                              onSelect={setSelectedTagsForCombining}
                              footer={
                                <>
                                  <Button type="outlined" size="m" text="Отменить" onClick={() => setTagsSelectCombiningOpened(false)} />
                                  <Button
                                    type="filled"
                                    size="m"
                                    text="Объединить"
                                    isDisabled={!selectedTagsForCombining.length}
                                    onClick={unite}
                                  />
                                </>
                              }
                            >
                              <Tooltip
                                className={`selection-page__tag-control ${
                                  tagsSelectCombiningOpened ? "selection-page__tag-control_active" : ""
                                }`}
                                idx="selectop-tag-unite"
                                text="Объединить"
                              >
                                <Button
                                  type="text-button"
                                  text=""
                                  icon="ui-icon ui-icon-add-group"
                                  onClick={() => setTagsSelectCombiningOpened(true)}
                                />
                              </Tooltip>
                            </PopupSearchTable>
                          </>
                        )}
                        <Tooltip
                          className={`selection-page__tag-control ${tagFilter.finalized ? "selection-page__tag-control_active" : ""}`}
                          idx="selectop-tag-finalize"
                          text={tagFilter.finalized ? "Финализировано" : chiefExpert ? "Финализировать" : "Не финализировано"}
                        >
                          <Button
                            type="text-button"
                            text=""
                            icon="ui-icon ui-icon-sports_score_black_24dp"
                            onClick={() => {
                              if (chiefExpert && !tagFilter.finalized) {
                                finalize(tagFilter.id);
                              }
                            }}
                          />
                        </Tooltip>
                        <div className="selection-page__tag-switcher">
                          <div onClick={prevTag}>
                            <i className="ui-icon ui-icon-keyboard-arrow-left" />
                            Предыдущая группа
                          </div>
                          <div onClick={nextTag}>
                            Следующая группа
                            <i className="ui-icon ui-icon-keyboard-arrow-right" />
                          </div>
                        </div>
                      </>
                    ) : (
                      <span className="selection-page__tag-counter">({tags?.length})</span>
                    )}
                  </div>
                )}

                {isTagEditing && !!tagFilter && (
                  <ClosingCard isOpened onClose={() => setIsTagEditing(false)}>
                    <SelectionEditTagForm tag={tagFilter} stageId={stageId} onSuccess={onEditTagSuccess} />
                  </ClosingCard>
                )}

                {!!tagFilter && (
                  <div className="selection-page__tag-idea-counter">
                    {tagFilter.proposalCount || 0} {declOfNum(tagFilter.proposalCount, ["идея", "идеи", "идей"])}
                  </div>
                )}

                <div className="selection-page__inputs">
                  <Select
                    value={filterTypeSearch}
                    items={filterTypesSearchSelect}
                    bindTo="text"
                    onItemSelect={onItemFilterTypeSelect}
                    closeOnSelect
                  />
                  <Search
                    value={seachFilterValue}
                    onChange={onChangeSearchFilter}
                    items={filterSearchRes}
                    bindTo="text"
                    isWithList
                    onItemSelect={onItemFilterSearchSelect}
                    isCloseOnSelect
                    icon={<span className="ui-icon ui-icon-search"></span>}
                    placeholder="Поиск идеи"
                  />
                  <DatePicker
                    type={DatePickerType.RANGED}
                    date={{ from: filter.from ? new Date(filter.from) : null, to: filter.to ? new Date(filter.to) : null }}
                    onChange={({ from, to }) => {
                      setFilter((prev) => ({
                        ...Object.fromEntries(Object.entries(prev).filter(([k]) => !["from", "to"].includes(k))),
                        ...(from && { from: getLocalDate(from) }),
                        ...(to && { to: getLocalDate(to) }),
                      }));
                    }}
                  />
                  {folder === "accepted" && (
                    <Switcher
                      defaultValue={filter.mainOnly || false}
                      onChange={(value) => setFilter((prev) => ({ ...prev, mainOnly: value }))}
                      html="Только главные"
                    />
                  )}
                </div>
              </div>
            )}
            {isFetching && !ideas.length && (
              <div className="selection-page__loader">
                <Loader />
              </div>
            )}
            <div className={isFetching ? "selection-page__fetching" : ""}>
              {ideas.map((idea) => (
                <div key={idea.id} className="selection-page__idea selection-page__block-indent">
                  <UserInfo
                    user={idea.author}
                    addInfoType="last-visit"
                    addNameText={`${genderizeWord("предложил", idea.author.gender)} ${idea.author.submittedProposals || 0} ${declOfNum(
                      idea.author.submittedProposals || 0,
                      ["идею", "идеи", "идей"]
                    )}`}
                  />

                  <div className="selection-page__idea__info">
                    {idea.main && <i className="ic ui-icon-Component4073 selection-page__idea__info__main-mark" />}
                    <SelectionIdeaInfo
                      idea={idea}
                      tags={tags}
                      showHistory={true}
                      canEditTag={!idea.main && chiefExpert}
                      showEditTage={ideaProcess === idea && ideaProcessType === "tagEditing"}
                      onEditTagSuccess={onEditTagSuccess}
                      onOpenEditTagState={(state) => {
                        if (state) {
                          setIdeaProcess(idea);
                          setIdeaProcessType("tagEditing");
                        } else {
                          setIdeaProcess(undefined);
                        }
                      }}
                    />
                  </div>

                  <div className="selection-page__idea__control selection-page__block-indent">
                    {ideaControlParams.map((p, i) => (
                      <Fragment key={i}>
                        {!p.isHidden?.(idea) &&
                          (p.isActive?.(idea) ? (
                            <div className="selection-page__idea__current-state">
                              <i className={`ic ${p.icon}`} /> {p.labelText}
                            </div>
                          ) : (
                            <Tooltip
                              idx={`idea-action-${idea.id}-${i}`}
                              text={p.tooltipText}
                              className={
                                (ideaProcess === idea && ideaProcessType !== "tagEditing") || p.isDisabled?.(idea) ? "disabled" : ""
                              }
                            >
                              <Button
                                type="text-button"
                                text=""
                                icon={p.icon}
                                onClick={() => {
                                  setIsTagEditing(false);
                                  p.action(idea);
                                }}
                              />
                            </Tooltip>
                          ))}
                      </Fragment>
                    ))}
                    {ideasExperts[idea.id] ? (
                      <UserInfo
                        user={ideasExperts[idea.id]}
                        addInfoType="role"
                        iconButton={{
                          icon: "ui-icon ui-icon-ic-restore-24px",
                          callback: () => {
                            setIdeaShowTransferHistory(idea);
                          },
                          tooltip: {
                            text: "История перемещения идеи",
                          },
                        }}
                      />
                    ) : null}
                  </div>

                  {ideaProcess === idea && (
                    <>
                      {ideaProcessType === "accepting" && (
                        <SelectionAcceptForm
                          isOpened
                          onClose={() => setIdeaProcess(undefined)}
                          stageId={stageId}
                          idea={idea}
                          tags={tags}
                          onSuccess={() => {
                            fetchCounts();
                            fetchIdeas();
                            fetchTags();
                          }}
                        />
                      )}

                      {ideaProcessType === "declining" && (
                        <DeclineProposalForm
                          stage={stage}
                          proposal={idea}
                          tag={tagsNormalized[idea.tagId]}
                          rejectReasonList={rejectReasons}
                          isOpened
                          onClose={() => setIdeaProcess(undefined)}
                          onDecline={() => {
                            if (filter.tagId && tagsNormalized[filter.tagId]?.proposalCount === 1) {
                              setFilter((prev) => Object.fromEntries(Object.entries(prev).filter(([k]) => k !== "tagId")));
                            } else {
                              fetchIdeas();
                            }
                            fetchCounts();
                            fetchTags();
                          }}
                        />
                      )}

                      {ideaProcessType === "forwarding" && (
                        <SelectionForwardForm
                          isOpened
                          onClose={() => setIdeaProcess(undefined)}
                          stageId={stageId}
                          idea={idea}
                          experts={experts}
                          onSuccess={() => {
                            fetchCounts();
                            fetchIdeas();
                            fetchTags();
                          }}
                        />
                      )}

                      {ideaProcessType === "moving" && (
                        <SelectionMoveForm
                          isOpened
                          onClose={() => setIdeaProcess(undefined)}
                          stageId={stageId}
                          idea={idea}
                          onSuccess={() => {
                            fetchCounts();
                            fetchIdeas();
                          }}
                        />
                      )}
                    </>
                  )}

                  {folder === SelectionFolders.REJECTED && idea !== ideaProcess && (
                    <ClosingCard isOpened>
                      <label className="selection-page__card-label-info">Причина отклонения</label>
                      <div className="break-word">{idea.reason}</div>
                      {!!idea.rejectComment && (
                        <>
                          <label className="selection-page__card-label-info">Комментарий</label>
                          <div className="break-word">{idea.rejectComment}</div>
                        </>
                      )}
                    </ClosingCard>
                  )}

                  {folder === SelectionFolders.FORWARDED && idea !== ideaProcess && (
                    <ClosingCard isOpened isSmall type="gray">
                      <label className="selection-page__card-label-info">Причина передачи</label>
                      <div className="break-word">{idea.comment}</div>
                    </ClosingCard>
                  )}
                </div>
              ))}
            </div>

            {folder === SelectionFolders.ACCEPTED &&
              (canFinalizeAll ? (
                <div className="selection-page__block-indent">
                  <Button type="outlined" text="Финализировать все группы" size="m" onClick={() => finalize()} />
                </div>
              ) : (
                canUnfinafileAll && (
                  <div className="selection-page__block-indent">
                    <Button type="outlined" text="Отменить финализацию всех групп" size="m" onClick={() => unfinalize()} />
                  </div>
                )
              ))}
          </div>

          <SelectionInfo stage={stage} onConfirmFinishStage={fetchStage} />
        </div>
      </>
    );
  };

  return isMobile ? (
    <MobileStubModal />
  ) : (
    <section className="selection-page">
      {isLoading ? (
        <div className="selection-page__loader">
          <Loader />
        </div>
      ) : (
        <>
          <PageTitle text={`Отбирай: ${stage.title}`} />
          <div className="selection-page__indicators">
            {!!remained && (
              <div className={`selection-page__remained ${stageFinishIsComing ? "selection-page__remained_finishing" : ""}`}>
                <i className="ui-icon ui-icon-clock" />
                {remained}
              </div>
            )}
            {chiefExpert && Boolean(counts[SelectionProposalCounters.UNREVIEWED]) && (
              <div className="selection-page__unreviewed">
                <i className="ui-icon ui-icon-visibility-off" />
                {counts[SelectionProposalCounters.UNREVIEWED]}
                {declOfNum(counts[SelectionProposalCounters.UNREVIEWED], [" Нерассмотренная", " Нерассмотренные", " Нерассмотренных"])}
                {declOfNum(counts[SelectionProposalCounters.UNREVIEWED], [" идея", " идеи", " идей"])}
              </div>
            )}
          </div>
          {renderMainContent()}
        </>
      )}
      {!!ideaShowTransferHistory && (
        <IdeaTransferHistoryModal proposal={ideaShowTransferHistory} onClose={() => setIdeaShowTransferHistory(undefined)} />
      )}
      {!!ideasForCombining && (
        <SelectMainIdeaModal
          isOpen
          title={`Выбор главной идеи для группы "${tagFilter.value}"`}
          onClose={() => setIdeasForCombining(undefined)}
          ideas={ideasForCombining}
          isConfirming={isCombiningTags}
          onConfirm={onConfirmSelectedMainIdea}
        />
      )}
      {reservationExpiredModalOpen && (
        <Modal isOpen onClose={() => window.location.reload()}>
          <Fragment key="body">Время рассмотрения идеи истекло. Пожалуйста, обновите страницу.</Fragment>
          <Fragment key="footer">
            <Button type="filled" text="Обновите страницу" onClick={() => window.location.reload()} />
          </Fragment>
        </Modal>
      )}
      {pagination?.totalElements > 0 && !isStageFinished && (
        <Pagination
          totalPages={pagination?.totalPages || 0}
          onPageChange={(event) => {
            setPage(event.selected);
            scrollToTop();
          }}
          currentPage={page}
          pageName="page"
        />
      )}
    </section>
  );
};

export default Selection;
