import { API } from "./api";

const baseSettingsUrl = "/api/settings";

const CommonService = {
  getEnvVariables: () => {
    return API.get(`${baseSettingsUrl}/env`).then((data) => {
      return data?.data;
    });
  },

  getLicense: () => {
    return API.get(`${baseSettingsUrl}/license`).then(({ data }) => {
      return data;
    });
  },
};

export default CommonService;
