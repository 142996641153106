import { API, httpParamsSerialize } from "./api";
import { IBaseListResponse, IBaseResponse } from "../App.interface";
import { IPagingParams } from "../types/Common.interface";
import {
  ChangeTagRequest,
  CountedProposalsByStatus,
  GetSelectionRequest,
  ProposalAcceptRequest,
  ProposalForwardRequest,
  ProposalInterface,
  ProposalMoveRequest,
  ProposalNode,
  ProposalTag,
  ProposalTransferHistory,
  RejectProposalQuery,
  RejectReason,
  RenameTagRequest,
  SelectionFinishConditions,
  SetMainProposalRequest,
  UniteTagsRequest,
} from "../types/Proposal.interface";
import { queryBuilder } from "../utils/urlUtils";

const baseUrl = "/api/proposal";

const ProposalService = {
  getProposalCount: (stageId?) => {
    return API.get(`${baseUrl}/count${stageId ? "?stageId=" + stageId : ""}`).then(({ data }) => {
      return data;
    });
  },
  getDraftCount: () => {
    return API.get(`${baseUrl}/draft/count`).then(({ data }) => {
      return data;
    });
  },
  getDraft: (id) => {
    return API.get(`${baseUrl}/draft/get?id=${id}`).then(({ data }) => {
      return data;
    });
  },
  getStageDrafts: (stageId) => {
    return API.get(`${baseUrl}/draft/get?stageId=${stageId}`).then(({ data }) => {
      return data;
    });
  },
  submitProposal: (formData: { [key: string]: any }, toDraft: boolean = false): Promise<IBaseResponse<any>> => {
    return API.post(`${baseUrl}/submit`, { ...formData, toDraft }).then(({ data }) => {
      return data;
    });
  },
  deleteDraft: (id) => {
    return API.post(`${baseUrl}/draft/delete`, { id }).then(({ data }) => data);
  },

  getProposalsByUserId: (userId: string, paging: IPagingParams): Promise<IBaseListResponse<ProposalInterface>> => {
    const params = httpParamsSerialize({ userId, ...paging });
    return API.get(`${baseUrl}/accepted/get?${params}`).then(({ data }) => {
      return data;
    });
  },

  getPersonalProposals: (stageId?, status?, page?, size?) => {
    let query = "";
    if (stageId) query += "stageId=" + stageId;
    if (status) query += `&status=${status}`;
    if (page) query += `&page=${page}`;
    if (size) query += `&size=${size}`;

    return API.get(`/api/personal/proposals/get?${query}`).then(({ data }) => data);
  },

  getPersonalProposalsCount: () => {
    return API.get(`/api/personal/proposals/count`).then(({ data }) => data);
  },

  getById: (proposalId: string): Promise<IBaseResponse<ProposalInterface>> => {
    return API.get(`/api/proposal/get?id=${proposalId}`).then(({ data }) => data);
  },

  getSelectionCount: (stageId: string): Promise<IBaseResponse<CountedProposalsByStatus>> => {
    return API.get(`${baseUrl}/selection/countProposals?stageId=${stageId}`).then(({ data }) => data);
  },

  getSelectionIncoming: (stageId: string, proposalId?: string): Promise<IBaseResponse<ProposalInterface>> => {
    return API.get(`${baseUrl}/selection/next?stageId=${stageId}${proposalId ? `&proposalId=${proposalId}` : ""}`).then(({ data }) => data);
  },

  getSelectionAccepted: (data: GetSelectionRequest): Promise<IBaseListResponse<ProposalInterface>> => {
    return API.get(`${baseUrl}/selection/accepted${queryBuilder(data)}`).then(({ data }) => data);
  },

  getSelectionRejected: (data: GetSelectionRequest): Promise<IBaseListResponse<ProposalInterface>> => {
    return API.get(`${baseUrl}/selection/rejected${queryBuilder(data)}`).then(({ data }) => data);
  },

  getSelectionForwarded: (data: GetSelectionRequest): Promise<IBaseListResponse<ProposalInterface>> => {
    return API.get(`${baseUrl}/selection/forwarded${queryBuilder(data)}`).then(({ data }) => data);
  },

  selectionAccept: (data: ProposalAcceptRequest): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/accept`, data).then(({ data }) => data);
  },

  selectionForward: (data: ProposalForwardRequest): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/forward`, data).then(({ data }) => data);
  },

  selectionMove: (data: ProposalMoveRequest): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/move`, data).then(({ data }) => data);
  },

  getTags: (stageId: string): Promise<IBaseResponse<ProposalTag[]>> => {
    return API.get(`${baseUrl}/tag/get?stageId=${stageId}`).then(({ data }) => data);
  },

  selectionsFindByTag: (stageId: string, tagValue: string): Promise<IBaseResponse<string[]>> => {
    return API.get(`${baseUrl}/selection/findByTag?stageId=${stageId}&tag=${encodeURIComponent(tagValue)}`).then(({ data }) => data);
  },

  selectionGetMainProposal: (stageId: string, tagValue: string): Promise<IBaseResponse<ProposalInterface>> => {
    return API.get(`${baseUrl}/selection/getMainProposal?stageId=${stageId}&tagValue=${encodeURIComponent(tagValue)}`).then(
      ({ data }) => data
    );
  },

  selectionSetMain: (data: SetMainProposalRequest) => {
    return API.post(`${baseUrl}/selection/setMain`, data).then(({ data }) => data);
  },

  selectionRenameTag: (data: RenameTagRequest): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/tag/rename`, data).then(({ data }) => data);
  },

  selectionChangeTag: (data: ChangeTagRequest): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/tag/change`, data).then(({ data }) => data);
  },

  selectionTagUnite: (data: UniteTagsRequest): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/tag/unite`, data).then(({ data }) => data);
  },

  selectionFinalize: (stageId: string, tagId?: string): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/tag/finalize`, { stageId, ...(!!tagId && { tagId }) }).then(({ data }) => data);
  },

  selectionUnfinalize: (stageId: string, tagId?: string): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/tag/unfinalize`, { stageId, ...(!!tagId && { tagId }) }).then(({ data }) => data);
  },

  readHistory: (proposalId: string): Promise<IBaseResponse<ProposalTransferHistory[]>> => {
    return API.get(`${baseUrl}/selection/readHistory?proposalId=${proposalId}`).then(({ data }) => data);
  },

  getRejectReasons: (stageId: string): Promise<IBaseResponse<RejectReason[]>> => {
    return API.get(`${baseUrl}/selection/getRejectReasons?stageId=${stageId}`).then(({ data }) => data);
  },

  reject: (query: RejectProposalQuery): Promise<IBaseResponse<void>> => {
    return API.post(`${baseUrl}/selection/reject`, query).then(({ data }) => data);
  },

  getAlternativeNodes: (stageId: string, proposalId: string): Promise<IBaseResponse<ProposalNode[]>> => {
    return API.get(`${baseUrl}/selection/getAlternativeNodes?stageId=${stageId}&proposalId=${proposalId}`).then(({ data }) => data);
  },

  getStageFinishConditions: (stageId: string): Promise<IBaseResponse<SelectionFinishConditions>> => {
    return API.get(`${baseUrl}/selection/finishConditions?stageId=${stageId}`).then(({ data }) => data);
  },
};

export default ProposalService;
