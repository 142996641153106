export enum SelectionFolders {
  INCOMING = "incoming",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  FORWARDED = "forwarded",
}

export const SelectionFoldersNames = {
  [SelectionFolders.INCOMING]: "Входящие",
  [SelectionFolders.ACCEPTED]: "Принятые",
  [SelectionFolders.REJECTED]: "Отклоненные",
  [SelectionFolders.FORWARDED]: "Переданные",
};

export enum SelectionProposalCounters {
  ACCEPTED = "ACCEPTED",
  FORWARDED = "FORWARDED",
  FORWARDED_TOTAL = "FORWARDED_TOTAL",
  REJECTED = "REJECTED",
  REMOVED = "REMOVED",
  SUBMITTED = "SUBMITTED",
  UNREVIEWED = "UNREVIEWED"
}

export const folderToCounterMap = {
  [SelectionFolders.INCOMING]: SelectionProposalCounters.SUBMITTED,
  [SelectionFolders.ACCEPTED]: SelectionProposalCounters.ACCEPTED,
  [SelectionFolders.REJECTED]: SelectionProposalCounters.REJECTED,
  [SelectionFolders.FORWARDED]: SelectionProposalCounters.FORWARDED
}

export const mapCounterToFolder = {
  SelectionFolders
}

