import { Button, Input, Loader } from "@crowd/ui-kit";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ProposalService from "../../../../services/proposalService";
import { hideInfoModal, showErrorInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { ProposalInterface, ProposalTag } from "../../../../types/Proposal.interface";
import { escapeRegExp } from "../../../../utils";
import ActionTag from "../../../presentational/ActionTag/ActionTag";
import ClosingCard from "../../../presentational/Cards/ClosingCard/ClosingCard";
import Groups from "../../../presentational/Groups/Groups";
import SelectionIdeaModal from "../SelectionIdeaModal/SelectionIdeaModal";
import SelectMainIdeaModal from "../SelectMainIdeaModal/SelectMainIdeaModal";
import { SelectionAcceptFormProps, SelectionAcceptFormType } from "./SelectionAcceptForm.interface";
import "./SelectionAcceptForm.scss";

const mapTypeToTitle = {
  [SelectionAcceptFormType.NEW]: "Добавьте группу",
  [SelectionAcceptFormType.EDIT]: "Редактируйте группу",
};
const mapTypeToSubmitTitle = {
  [SelectionAcceptFormType.NEW]: "Добавить",
  [SelectionAcceptFormType.EDIT]: "Изменить",
};

const SelectionAcceptForm: FC<SelectionAcceptFormProps> = ({
  type = SelectionAcceptFormType.NEW,
  stageId,
  tags,
  currentTag,
  idea,
  onSuccess,
  isOpened,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [tagValueAccept, setTagValueAccept] = useState(currentTag || "");
  const [isAccepting, setIsAccepting] = useState(false);
  const [isPreAccepting, setIsPreAccepting] = useState(false);
  const [selectMainFromIdeas, setSelectMainFromIdeas] = useState<{
    idea: ProposalInterface;
    otherIdeas: ProposalInterface[];
  }>();
  const [ideaShowModal, setIdeaShowModal] = useState<ProposalInterface>();

  const filteredTags = useMemo(() => {
    const r = new RegExp(escapeRegExp(tagValueAccept), "i");
    return tags?.filter(({ value }) => value.match(r));
  }, [tags, tagValueAccept]);

  const acceptIdea = async (idea: ProposalInterface, isMain: boolean) => {
    setIsAccepting(true);

    try {
      const { status, message } = await ProposalService.selectionAccept({
        id: idea.id,
        stageId,
        tagValue: tagValueAccept,
        setMain: isMain,
      });

      if (status === ApiStatusCode.OBJECT_IS_NOT_RESERVED_BY_USER) {
        throw new Error("Время рассмотрения идеи истекло. Пожалуйста, обновите страницу.");
      } else if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      setIsAccepting(false);
      onSuccess?.();
      onClose();
    } catch (e: any) {
      setIsAccepting(false);
      dispatch(showErrorInfoModal(e.message));
    }
  };

  const changeIdeaTag = async (idea: ProposalInterface, isMain: boolean) => {
    setIsAccepting(true);

    try {
      const { status, message } = await ProposalService.selectionChangeTag({
        id: idea.id,
        stageId,
        tagValue: tagValueAccept,
        setMain: isMain,
      });

      if (status === ApiStatusCode.OBJECT_IS_NOT_RESERVED_BY_USER) {
        throw new Error("Время рассмотрения идеи истекло. Пожалуйста, обновите страницу.");
      } else if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      setIsAccepting(false);
      onSuccess?.();
      onClose();
    } catch (e: any) {
      setIsAccepting(false);
      dispatch(showErrorInfoModal(e.message));
    }
  };

  const acceptIdeaAndAddToTag = async (idea: ProposalInterface) => {
    setIsPreAccepting(true);

    try {
      const { data, status, message } = await ProposalService.selectionsFindByTag(stageId, tagValueAccept);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      if (data.length > 0) {
        const { data, status, message } = await ProposalService.selectionGetMainProposal(stageId, tagValueAccept);
        if (status !== ApiStatusCode.OK) {
          throw new Error(message);
        }

        setSelectMainFromIdeas({ idea, otherIdeas: [data] });
      } else {
        (await type) === SelectionAcceptFormType.NEW ? acceptIdea(idea, true) : changeIdeaTag(idea, true);
      }
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    } finally {
      setIsPreAccepting(false);
    }
  };

  useEffect(() => {
    setTagValueAccept(currentTag || "");
  }, [idea]);

  const renderTag = (tag: ProposalTag) => (
    <ActionTag
      title={tag.value}
      items={[
        { text: "Выбрать группу", action: () => setTagValueAccept(tag.value) },
        {
          text: "Показать главную идею",
          action: async () => {
            dispatch(showInfoModal("", "", <Loader />));
            try {
              const { data, status, message } = await ProposalService.selectionGetMainProposal(stageId, tag.value);
              if (status !== ApiStatusCode.OK) {
                throw new Error(message);
              }
              setIdeaShowModal(data);
            } catch (e: any) {
              dispatch(showErrorToast(e.message));
            } finally {
              dispatch(hideInfoModal());
            }
          },
        },
        { text: "Перейти в группу", action: () => window.open(`/select/accepted/${stageId}?tagId=${tag.id}`) },
      ]}
    />
  );

  return (
    <>
      {!!ideaShowModal && <SelectionIdeaModal isOpen onClose={() => setIdeaShowModal(undefined)} idea={ideaShowModal} />}
      {!!selectMainFromIdeas && (
        <SelectMainIdeaModal
          isOpen
          onClose={() => setSelectMainFromIdeas(undefined)}
          ideas={[selectMainFromIdeas.idea, ...selectMainFromIdeas.otherIdeas]}
          onConfirm={async (selectedIdea) => {
            (await type) === SelectionAcceptFormType.NEW
              ? acceptIdea(selectMainFromIdeas.idea, selectMainFromIdeas.idea === selectedIdea)
              : changeIdeaTag(idea, selectedIdea === idea);
            setSelectMainFromIdeas(undefined);
          }}
          isConfirming={isAccepting}
          title={`Выбор главной идеи для группы «${tagValueAccept}»`}
        />
      )}
      <ClosingCard className="selection-accept-form" isOpened={isOpened} onClose={onClose}>
        <label>{mapTypeToTitle[type]}</label>
        <Input
          value={tagValueAccept}
          onChange={setTagValueAccept}
          placeholder="Введите название группы"
          shouldValidate
          validateRules={{ notEmpty: true, maxLength: 60 }}
          maxLength={60}
        />
        <Groups items={filteredTags} bindTo="value" renderItem={renderTag} />
        <div className="selection-accept-form__buttons">
          <Button
            type="filled"
            text={mapTypeToSubmitTitle[type]}
            isDisabled={!tagValueAccept}
            isLoading={isPreAccepting || isAccepting}
            onClick={() => acceptIdeaAndAddToTag(idea)}
            size="m"
          />
        </div>
      </ClosingCard>
    </>
  );
};

export default SelectionAcceptForm;
