import { IPagingParams } from "./Common.interface";
import { Stage } from "./Stage.interface";
import { User } from "./User.interface";

export interface ProposalState {
  drafts: any;
  count: number | null;
  storedDraft: any;
}

export enum ProposalTypeEnum {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  NOT_SUBMITTED = "NOT_SUBMITTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REMOVED = "REMOVED",
  FORWARDED = "FORWARDED",
}

export interface ReservationDto {
  user: User;
  timestamp: string;
}

export interface ProposalInterface {
  attachments: any[];
  author: User;
  authorId: string;
  discussionId: string;
  draftId: string;
  finalist: boolean;
  id: string;
  items: any[];
  nodeId: string;
  oldStageId: string;
  permissions: string[];
  reason: any;
  selectedMain: boolean;
  stage: Stage;
  stageId: string;
  selectionStageId: string;
  status: ProposalTypeEnum;
  statusDescription: string;
  statusTitle: string;
  submissionDate: string;
  lastEdited: string;
  lastSubmitted: string;
  title: string;
  winner: boolean;
  main: boolean;
  tag: string | null;
  tagId: string | null;
  expert: User;
  comment: string;
  rejectComment: string;
  hasEdited: boolean;
  statusHistory: ProposalTransferHistory[];
  reservation?: ReservationDto;
}

export enum TaskType {
  FIELD = "FIELD",
  LIST = "LIST",
}

export interface ProposalTaskItem {
  id: string;
  title: string;
  description: string;
  required: boolean;
  type: "FIELD" | "LIST";
  items: {
    value: string;
    orderNumber: number;
  }[];
}

export interface ProposalAcceptRequest {
  id: string;
  stageId: string;
  text?: string;
  expertId?: string;
  tagValue: string;
  setMain: boolean;
  altTitle?: string;
  mainIdCompared?: string;
}

export interface ProposalForwardRequest {
  id: string;
  stageId: string;
  text: string;
  expertId: string;
}

export interface ProposalMoveRequest {
  proposalId: string;
  stageId: string;
  targetNodeId: string;
}

export interface CountedProposalsByStatus {
  submitted: number;
  accepted: number;
  rejected: number;
  forwarded: number;
  forwardedTotal: number;
  removed: number;
}

export enum ProposalTagPermissions {
  TAG_FINALIZE = "TAG_FINALIZE",
  TAG_RENAME = "TAG_RENAME",
  TAG_UNION = "TAG_UNION",
}

export interface ProposalTag {
  id: string;
  nodeId: string;
  mainProposalId: string;
  mainProposalAuthor: User;
  timestamp: string;
  value: string;
  finalized: boolean;
  noVoting: boolean;
  noImprovement: boolean;
  proposalCount: number;
  permissions: ProposalTagPermissions[];
}

export type GetSelectionRequest = {
  stageId: string;
  tagId?: string;
  from?: string;
  to?: string;
  expertId?: string;
  authorId?: string;
  title?: string;
  altTitle?: string;
  text?: string;
  mainOnly?: boolean;
  sort?: string[];
} & Partial<IPagingParams>;

export interface RenameTagRequest {
  stageId: string;
  tagId: string;
  newValue: string;
}

export interface ChangeTagRequest {
  id: string;
  mainIdCompared?: string;
  setMain: boolean;
  stageId: string;
  tagValue: string;
}

export interface UniteTagsRequest {
  stageId: string;
  sourceTagIds: string[];
  targetTagId: string;
  mainProposalId: string;
}

export interface ProposalTransferHistory {
  user: User;
  forwardedBy?: User;
  comment: string;
  timestamp: string;
  type: string;
  status: ProposalTypeEnum;
}

export enum ProposalStatusChangeEnum {
  STATUS_CHANGE = "STATUS_CHANGE",
  TAG_CHANGED = "TAG_CHANGED",
  ALT_TITLE_CHANGE = "ALT_TITLE_CHANGE",
  MAIN_PROPOSAL_CHANGE = "MAIN_PROPOSAL_CHANGE",
  PROPOSAL_AS_MAIN = "PROPOSAL_AS_MAIN",
  TAGS_UNITED = "TAGS_UNITED",
  NODE_CHANGED = "NODE_CHANGED",
}

export const ProposalStatusChangeMap = {
  [ProposalStatusChangeEnum.STATUS_CHANGE]: "Изменен статус",
  [ProposalStatusChangeEnum.TAG_CHANGED]: "Изменена группа",
  [ProposalStatusChangeEnum.ALT_TITLE_CHANGE]: "Изменен альтернативный заголовок",
  [ProposalStatusChangeEnum.MAIN_PROPOSAL_CHANGE]: "В группе изменена главная идея",
  [ProposalStatusChangeEnum.PROPOSAL_AS_MAIN]: "Идея сделана главной в группе",
  [ProposalStatusChangeEnum.TAGS_UNITED]: "Объединены группы",
  [ProposalStatusChangeEnum.NODE_CHANGED]: "Идея перемещена",
};

export const ProposalStatusMap = {
  [ProposalTypeEnum.DRAFT]: "Черновик",
  [ProposalTypeEnum.SUBMITTED]: "На рассмотрении",
  [ProposalTypeEnum.NOT_SUBMITTED]: "Не отправлена",
  [ProposalTypeEnum.ACCEPTED]: "Принята",
  [ProposalTypeEnum.REJECTED]: "Отклонена",
  [ProposalTypeEnum.REMOVED]: "Удалена",
  [ProposalTypeEnum.FORWARDED]: "Передана",
};

export interface RejectProposalQuery {
  id: string;
  stageId: string;
  reasonId: string;
  comment: string;
}

export interface RejectReason {
  id: string;
  title: string;
  hidden: boolean;
}

export interface ProposalNode {
  blogId: string;
  description: string;
  id: string;
  stages: Stage[];
  title: string;
}

export interface SelectionFinishConditions {
  generationFinished: boolean;
  allFinalized: boolean;
  allExpertsNotified: boolean;
  hasAccepted: boolean;
}

export interface SetMainProposalRequest {
  stageId: string;
  proposalId: string;
}
