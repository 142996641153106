import { Modal } from "@crowd/ui-kit";
import { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../Header/Logo/Logo";
import "./MobileStubModal.scss";

const MobileStubModal: FC = () => {
  const history = useHistory();
  return (
    <Modal classNames="mobile-stub-modal" isOpen onClose={() => history.push("/")}>
      <Fragment key="header">
        <Logo />
        <div className="mobile-stub-modal__title">ВНИМАНИЕ</div>
      </Fragment>
      <Fragment key="body">Для работы с идеями откройте страницу на компьютере.</Fragment>
    </Modal>
  );
};

export default MobileStubModal;
