import { Button, Modal } from "@crowd/ui-kit";
import { FC, Fragment, useState } from "react";
import SelectionIdeaInfo from "../SelectionIdeaInfo/SelectionIdeaInfo";
import { SelectMainIdeaModalProps } from "./SelectMainIdeaModal.interface";
import "./SelectMainIdeaModal.scss";

const SelectMainIdeaModal: FC<SelectMainIdeaModalProps> = ({ ideas, onClose, isOpen, title, isConfirming, onConfirm }) => {
  const [selectedIdea, setSelectedIdea] = useState(() => ideas.find(({ main }) => main));

  return (
    <Modal classNames="select-main-idea-modal" isOpen={isOpen} onClose={onClose}>
      <Fragment key="header">{title}</Fragment>
      <Fragment key="body">
        <div className="select-main-idea-modal__ideas">
          {ideas.map((idea) => (
            <div
              key={idea.id}
              onClick={() => setSelectedIdea(idea)}
              className={`select-main-idea-modal__ideas__item ${idea === selectedIdea ? "select-main-idea-modal__ideas__item_active" : ""}`}
            >
              {idea === selectedIdea && <i className="ic ui-icon-Component4073 select-main-idea-modal__selected-mark" />}
              <SelectionIdeaInfo idea={idea} showTag={false} showAttachments={false} />
            </div>
          ))}
        </div>
      </Fragment>
      <Fragment key="footer">
        <Button type="outlined" text="Отменить" onClick={onClose} size="m" />
        <Button type="filled" text="Подтвердить выбор" onClick={() => onConfirm(selectedIdea)} size="m" isLoading={isConfirming} />
      </Fragment>
    </Modal>
  );
};

export default SelectMainIdeaModal;
