import { StageState } from "../../types/Stage.interface";
import { GET_ACCESSIBLE_IDS, GET_ALL_STAGES } from "../actions/StageActions";

const initialState: StageState = {
  allStages: null,
  accessibleStages: null,
  accessibleIds: null,
};

export function stageReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_STAGES:
      return { ...state, allStages: action.payload } as StageState;
    case GET_ACCESSIBLE_IDS:
      return {
        ...state,
        accessibleIds: action.payload,
        accessibleStages: state.allStages?.filter((stage) =>
          action.payload.includes(stage.id)
        ),
      };
    default:
      return state;
  }
}
