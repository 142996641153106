import React, { useContext, useEffect, useState } from "react";
import { User } from "../../../../types/User.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import { useInterval } from "../../../../utils/hooks/useInterval";
import { Link } from "react-router-dom";
import UserAvatar from "../../../presentational/UserAvatar/UserAvatar";
import UserModal from "../../../containers/Modals/UserModal/UserModal";
import { AppContext } from "../../../../Root";
import { AuthActionType, ComponentType } from "../../../../services/sudirService";

import "./ProjectParticipants.scss";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

const ProjectParticipants = ({ participants }) => {
  const appContext = useContext(AppContext);
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [visibleParticipants, setVisibleParticipants] = useState<User[]>(null);
  const [startParticipantSlicingFrom, setStartParticipantSlicingFrom] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState(null);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (participants?.length) {
      const actions = appContext.sudirService.getActions(ComponentType.ProjectParticipants);
      if (actions?.length) {
        const openUserDetailsModalAction = actions.find((act) => act.type === AuthActionType.OpenUserDetailsModal);
        if (openUserDetailsModalAction) {
          const member = participants.find((member) => member.id === openUserDetailsModalAction.args.userId);
          member && setSelectedUser(member);
        }
      }
    }
  }, [participants]);

  useEffect(() => {
    setStartParticipantSlicingFrom(0);
    _updateVisibleParticipants();
  }, [participants]);

  useInterval(_updateVisibleParticipants, 20000);

  function _getDisplayCount() {
    return isMobile ? 5 : isTablet ? 10 : 6;
  }

  function _updateVisibleParticipants() {
    if (!participants?.length) return;
    const displayCount = _getDisplayCount();
    const start = startParticipantSlicingFrom;
    const end = start + displayCount;

    if (end > participants.length) {
      setVisibleParticipants(participants.slice(start, participants.length));
      setStartParticipantSlicingFrom(0);
    } else {
      setVisibleParticipants(participants.slice(start, end));
      setStartParticipantSlicingFrom(end);
    }
  }

  return (
    <>
      <div className="project-participants-container">
        <div className="project-participants-list">
          {visibleParticipants?.map((participant) => (
            <EventsTrackWrapperClick
              key={participant.id}
              id={["BUTTON", "CLICK"]}
              needParent
              params={{ replace: ["Участник"], remember: true }}
            >
              <div className="project-participants-item" onClick={() => setSelectedUser(participant)}>
                <UserAvatar user={participant} size={isTablet || isMobile ? "XS" : "S"} />
                <div className="project-participants-name">
                  <span>{participant.firstName}</span>
                  <span>{participant.lastName}</span>
                </div>
              </div>
            </EventsTrackWrapperClick>
          ))}
        </div>
        {participants?.length > _getDisplayCount() && (
          <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: [isTablet ? "Все" : "Все участники"] }}>
            <Link to={"/users/participants"} className="project-participants-more">
              {isTablet ? "Все" : "Все участники"}
            </Link>
          </EventsTrackWrapperClick>
        )}
      </div>
      {selectedUser && <UserModal userId={selectedUser.id} onClose={() => setSelectedUser(null)} />}
    </>
  );
};

export default ProjectParticipants;
