import { Button } from "@crowd/ui-kit";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ProjectService from "../../../../services/projectService";
import ProposalService from "../../../../services/proposalService";
import { showConfirmInfoModal, showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { SelectionFinishConditions } from "../../../../types/Proposal.interface";
import { StagePermissionsString } from "../../../../types/Stage.interface";
import { SelectionInfoProps } from "./SelectionInfo.interface";
import "./SelectionInfo.scss";

const availablePermissions: StagePermissionsString[] = ["STAGE_STOP", "STAGE_VIEW_FINISH_CONDITIONS", "STAGE_CONFIRM_FINISH"];

const SelectionInfo: FC<SelectionInfoProps> = ({ stage, onConfirmFinishStage }) => {
  const dispatch = useDispatch();
  const canRender = useMemo(() => !!stage.permissions?.filter((p) => availablePermissions.includes(p)).length, [stage.permissions]);
  const [conditions, setConditions] = useState<SelectionFinishConditions>();
  const [confirmedFinish, setConfirmedFinish] = useState(false);

  const stopStage = () => {
    dispatch(
      showConfirmInfoModal("Вы действительно хотите завершить этап?", async () => {
        try {
          const { status, message } = await ProjectService.stopStage(stage.id);
          if (status !== ApiStatusCode.OK) {
            throw new Error(message);
          }
          window.location.reload();
        } catch (e: any) {
          dispatch(showErrorInfoModal(e.message));
        }
      })
    );
  };

  const confirmFinish = async () => {
    try {
      const { status, message } = await ProjectService.confirmFinishStage(stage.id);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }
      setConfirmedFinish(true);
      onConfirmFinishStage?.();
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    }
  };

  useEffect(() => {
    ProposalService.getStageFinishConditions(stage.id).then((data) => setConditions(data.data));
  }, [stage]);

  return (
    canRender && (
      <div className="selection-info">
        <div className="selection-info__head">
          Завершение работ
          <i className="ui-icon ui-icon-sports_score_black_24dp" />
        </div>

        <div className="selection-info__body">
          {stage.permissions.includes("STAGE_STOP") ? (
            <>
              Главный эксперт подтвердил завершение работ на этапе. Теперь вы можете его завершить.
              <Button type="filled" size="m" text="Завершить этап" onClick={stopStage} />
            </>
          ) : stage.finishConfirmed ? (
            <>Вы подтвердили завершение работ на этапе.</>
          ) : (
            stage.permissions.includes("STAGE_VIEW_FINISH_CONDITIONS") &&
            !!conditions && (
              <div className="selection-info__criteria">
                <div>
                  <strong>Критерии:</strong>
                </div>
                <div>
                  Связанный этап генерации завершился
                  <i className={`ui-icon ${conditions.generationFinished ? "ui-icon-done-symbol" : "ui-icon-close"}`} />
                </div>
                <div>
                  Все группы финализированы
                  <i className={`ui-icon ${conditions.allFinalized && conditions.hasAccepted ? "ui-icon-done-symbol" : "ui-icon-close"}`} />
                </div>
                <div>
                  Все идеи обработаны
                  <i
                    className={`ui-icon ${
                      conditions.allExpertsNotified && conditions.hasAccepted ? "ui-icon-done-symbol" : "ui-icon-close"
                    }`}
                  />
                </div>
              </div>
            )
          )}
        </div>

        {stage.permissions.includes("STAGE_CONFIRM_FINISH") && (
          <div className="selection-info__footer">
            {!confirmedFinish ? (
              <Button type="filled" size="m" text="Подтвердить завершение" onClick={confirmFinish} />
            ) : (
              <>Вы подтвердили завершение этапа.</>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default SelectionInfo;
