import { Button, Select, Textarea } from "@crowd/ui-kit";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { ProposalInterface, ProposalTag, RejectProposalQuery, RejectReason } from "../../../../types/Proposal.interface";

import "./DeclineProposalForm.scss";
import ProposalService from "../../../../services/proposalService";
import { showConfirmInfoModal, showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { Stage } from "../../../../types/Stage.interface";
import ClosingCard from "../../../presentational/Cards/ClosingCard/ClosingCard";
import Validator from "@crowd/ui-kit/build/classes/Validator";

interface DeclineProposalFormProps {
  proposal: ProposalInterface;
  tag: ProposalTag;
  stage: Stage;
  rejectReasonList: RejectReason[];
  isOpened: boolean;
  onClose: () => void;
  onDecline: () => void;
}

const DeclineProposalForm: FC<DeclineProposalFormProps> = ({ proposal, tag, stage, rejectReasonList, isOpened, onClose, onDecline }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState("");
  const [commentValidator, setCommentValidator] = useState<Validator>(null);
  const [submitting, setSubmitting] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const isLastProposal = () => !!tag && tag.proposalCount === 1;

  const handleDecline = () => {
    if (isLastProposal()) {
      dispatch(
        showConfirmInfoModal(
          `При отклонении последней идеи в группе вся группа будет удалена. </br> Вы действительно хотите отклонить идею?`,
          decline,
          onClose
        )
      );
    } else {
      decline();
    }
  };

  const decline = async () => {
    try {
      setSubmitting(true);
      const query: RejectProposalQuery = {
        id: proposal.id,
        stageId: stage.id,
        reasonId: reason.id,
        comment: comment || null,
      };
      const { status, message } = await ProposalService.reject(query);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      setSubmitting(false);
      onDecline?.();
      onClose?.();
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
      setSubmitting(false);
    }
  };

  const isFormValid = () => {
    return !!reason && (!comment || commentValidator?.isValid());
  };

  return (
    <ClosingCard isOpened={isOpened} onClose={onClose}>
      <div className="decline-proposal-form">
        <div className="decline-proposal-form__title">Выберите причину отклонения</div>
        <div className="decline-proposal-form__body">
          <Select
            items={rejectReasonList}
            value={reason}
            bindTo="title"
            onItemSelect={(item) => setReason(item)}
            placeholder={"Причина отклонения"}
            closeOnSelect
          />
          {showComment && (
            <Textarea
              value={comment}
              placeholder={"Ваш комментарий"}
              hint={"Не более 1500 символов"}
              isRequired={false}
              onChange={setComment}
              shouldValidate
              maxLength={1500}
              validateRules={{
                minLength: 3,
                maxLength: 1500,
              }}
              onInitValidator={setCommentValidator}
              showError={!!comment}
            />
          )}
        </div>
        <div className="decline-proposal-form__footer">
          {!showComment && <Button type="filled" onClick={() => setShowComment(true)} text="Написать комментарий" size="m" />}
          <Button type="filled" onClick={handleDecline} text="Отклонить" size="m" isDisabled={!isFormValid()} isLoading={submitting} />
        </div>
      </div>
    </ClosingCard>
  );
};

export default DeclineProposalForm;
