import { ProposalInterface, ProposalTag } from "../../../../types/Proposal.interface";
import { ClosingCardProps } from "../../../presentational/Cards/ClosingCard/ClosingCard.interface";

export interface SelectionAcceptFormProps extends Pick<ClosingCardProps, "isOpened" | "onClose"> {
  type?: SelectionAcceptFormType;
  stageId: string;
  idea: ProposalInterface;
  tags?: ProposalTag[];
  currentTag?: string;
  onSuccess?: () => void;
}

export enum SelectionAcceptFormType {
  NEW = "NEW",
  EDIT = "EDIT"
}
