import { FC, useLayoutEffect, useRef } from "react";
import { useOuterClick } from "../../../utils/hooks/useOnOutsideClick";
import { PopupProps } from "./Popup.interface";
import "./Popup.scss";

const Popup: FC<PopupProps> = ({ isOpen, onClose, header, body, footer, children, className }) => {
  const ref = useOuterClick<HTMLDivElement>(onClose);
  const popupRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (isOpen) {
      const { width } = popupRef.current.getBoundingClientRect();
      const { left } = ref.current.getBoundingClientRect();
      popupRef.current.classList.add(width + left < window.innerWidth ? "popup_left" : "popup_right");
    }
  }, [isOpen]);

  return (
    <div ref={ref} className={`popup__wrapper ${className || ""}`}>
      {children}
      {isOpen && (
        <div className="popup" ref={popupRef}>
          {header && <div className="popup__header">{header}</div>}
          <div className="popup__body">{body}</div>
          {footer && <div className="popup__footer">{footer}</div>}
        </div>
      )}
    </div>
  );
};

export default Popup;
